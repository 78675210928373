import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { AuthProvider } from './authcontext/AuthContext'; // Adjust the import path as necessary
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./components/landingpageComponents/style/flexboxgrid.min.css";
import "./components/landingpageComponents/style/index.css";

const initialOptions = {
  "client-id": "AbUgOK8jaVZpBEbJQQrXEMB7dU7tbmawOUibRROc_cg3T5O1t4ApozqBqeWaU_S4THaoynNbI2FiOa5e"
};

// Create a root element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app
root.render(
  <React.StrictMode>
    <PayPalScriptProvider options={initialOptions}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </PayPalScriptProvider>
  </React.StrictMode>
);
