import React, { useState, useEffect } from 'react';
import { Tabs, Form, Input, Button, InputNumber, Upload, message, Table, Popconfirm, Modal } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import './vehicleRoute.css';

const { TabPane } = Tabs;

const VehicleAndRoute = () => {
  const [vehicleForm] = Form.useForm();
  const [priceForm] = Form.useForm();
  const [pricePerHourForm] = Form.useForm();
  const [vehicles, setVehicles] = useState([]);
  const [isVehicleModalVisible, setIsVehicleModalVisible] = useState(false);
  const [editingVehicle, setEditingVehicle] = useState(null);
  const [pricePerKilometer, setPricePerKilometer] = useState(0);
  const [imageForm] = Form.useForm();
  const [images, setImages] = useState([]);
  const [editingImage, setEditingImage] = useState(null);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [pricePerHour, setPricePerHour] = useState(0);
  useEffect(() => {
    fetchVehicles();
    fetchPricePerKilometer();
    fetchPricePerHour();
    fetchImages();
  }, []); // Only run once on component mount

  // Fetch vehicle data from the API
  const fetchVehicles = async () => {
    console.log('Fetching vehicles...');
    try {
      const res = await axios.get('https://johnwayneshuttle.com/api/vehicles');
      console.log('Response data:', res.data);
      setVehicles(res.data); // Directly use the array from the response
    } catch (error) {
      message.error('Failed to fetch vehicles');
    }
  };
  useEffect(() => {
    if (editingVehicle) {
      // Update form fields with the selected vehicle's data
      vehicleForm.setFieldsValue({
        vehicleName: editingVehicle.vehicleName,
        numberOfPassengers: editingVehicle.numberOfPassengers,
        vehiclePrice: editingVehicle.vehiclePrice,
        image: editingVehicle.image ? [{ url: `https://johnwayneshuttle.com/uploads/${editingVehicle.image}` }] : [],
      });
    }
  }, [editingVehicle, vehicleForm]);




  // Handle vehicle submission (add new vehicle)
  const handleVehicleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('vehicleName', values.vehicleName);
    formData.append('numberOfPassengers', values.numberOfPassengers);
    formData.append('vehiclePrice', values.vehiclePrice);
    formData.append('image', values.image[0].originFileObj);

    try {
      await axios.post('https://johnwayneshuttle.com/api/vehicles', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      message.success('Vehicle added successfully');
      vehicleForm.resetFields();
      fetchVehicles(); // Refresh the vehicle list after adding a new one
    } catch (error) {
      message.error('Failed to add vehicle');
    }
  };

  // Handle editing a vehicle
 // Handle editing a vehicle
const handleEditVehicle = (vehicle) => {
  setEditingVehicle(vehicle); // Set the selected vehicle in the state
  setIsVehicleModalVisible(true); // Open modal for editing
};



  // Handle updating a vehicle
  const handleVehicleUpdate = async (values) => {
    const formData = new FormData();
    formData.append('vehicleName', values.vehicleName);
    formData.append('numberOfPassengers', values.numberOfPassengers);
    formData.append('vehiclePrice', values.vehiclePrice);
    if (values.image && values.image[0]) {
      formData.append('image', values.image[0].originFileObj);
    }

    try {
      await axios.put(`https://johnwayneshuttle.com/api/vehicles/${editingVehicle._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      message.success('Vehicle updated successfully');
      setIsVehicleModalVisible(false);
      setEditingVehicle(null);
      fetchVehicles(); // Refresh the vehicle list after update
    } catch (error) {
      message.error('Failed to update vehicle');
    }
  };


  // Handle deleting a vehicle
  const handleDeleteVehicle = async (id) => {
    try {
      await axios.delete(`https://johnwayneshuttle.com/api/vehicles/${id}`);
      message.success('Vehicle deleted successfully');
      fetchVehicles(); // Refresh the vehicle list after deletion
    } catch (error) {
      message.error('Failed to delete vehicle');
    }
  };

  // Define table columns
  const vehicleColumns = [
    {
      title: 'Vehicle Name',
      dataIndex: 'vehicleName',
      key: 'vehicleName',
    },
    {
      title: 'Number of Passengers',
      dataIndex: 'numberOfPassengers',
      key: 'numberOfPassengers',
    },
    {
     title:"Vehicle Price",
      dataIndex:"vehiclePrice",
      key:"vehiclePrice",
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (imageFileName) => {
        const imageUrl = `https://johnwayneshuttle.com/uploads/${imageFileName}`;
        return <img src={imageUrl} alt="vehicle" style={{ width: '100px', height: 'auto' }} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <EditOutlined onClick={() => handleEditVehicle(record)} style={{ marginRight: 16 }} />
          <Popconfirm title="Are you sure to delete?" onConfirm={() => handleDeleteVehicle(record._id)}>
            <DeleteOutlined />
          </Popconfirm>
        </>
      ),
    },
  ];
  const handlePriceSubmit = async (values) => {
    try {
      await axios.post('https://johnwayneshuttle.com/api/price-per-kilometer', { price: values.pricePerKilometer });
      message.success('Price per kilometer updated successfully');
      fetchPricePerKilometer();
    } catch (error) {
      message.error('Failed to update price per kilometer');
    }
  };
  const handlePricePerHourSubmit = async (values) => {

    try {
      await axios.post('https://johnwayneshuttle.com/api/price-per-hour', { pricePerHour: values.pricePerHour });
      message.success('Price per hour updated successfully');
      fetchPricePerHour();
    } catch (error) {
      message.error('Failed to update price per hour');
    }
  };

  const fetchPricePerHour = async () => {
    try {
      const res = await axios.get('https://johnwayneshuttle.com/api/price-per-hour');
      setPricePerHour(res.data.price); // Ensure correct assignment
    } catch (error) {
      message.error('Failed to fetch price per hour');
    }
  };

  const fetchPricePerKilometer = async () => {
    try {
      const res = await axios.get('https://johnwayneshuttle.com/api/price-per-kilometer');
      setPricePerKilometer(res.data.price); // Ensure correct assignment
    } catch (error) {
      message.error('Failed to fetch price per kilometer');
    }
  };




  // const [images, setImages] = useState([]);



// Fetch images
const fetchImages = async () => {
  try {
    const res = await axios.get('https://johnwayneshuttle.com/api/images');
    setImages(res.data);
  } catch (error) {
    message.error('Failed to fetch images');
  }
};

// Handle image submission
const handleImageSubmit = async (values) => {
  const formData = new FormData();
  formData.append('image', values.image[0].originFileObj);
  formData.append('description', values.description);

  try {
    await axios.post('https://johnwayneshuttle.com/api/images', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    message.success('Image added successfully');
    fetchImages();
  } catch (error) {
    message.error('Failed to add image');
  }
};
const handleImageUpdate = async (values) => {
  try {
    const formData = new FormData();
    formData.append('description', values.description);
    if (values.image && values.image[0]) {
      formData.append('image', values.image[0].originFileObj);
    }

    // Send PUT request to update image
    const response = await axios.put(`https://johnwayneshuttle.com/api/images/${editingImage._id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Close modal and reset form
    setIsImageModalVisible(false);
    setEditingImage(null); // Reset editingImage when modal is closed
    imageForm.resetFields();

    // Reload the page after successful update
    window.location.reload();  // This will reload the page to show the updated image

    // Handle success (optional)
    console.log('Image updated:', response.data);
  } catch (error) {
    console.error('Error updating image:', error);
  }
};

const handleEditImage = (image) => {
  setEditingImage(image);
  setIsImageModalVisible(true); // Open modal for editing
};

const handleDeleteImage = async (id) => {
  try {
    await axios.delete(`https://johnwayneshuttle.com/api/images/${id}`);
    message.success('Image deleted successfully');
    fetchImages(); // Refresh the image list after deletion
  } catch (error) {
    message.error('Failed to delete image');
  }
};
// Define table columns for images
const imageColumns = [
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: (imageFileName) => {
      const imageUrl = `https://johnwayneshuttle.com/uploads/${imageFileName}`;
      return <img src={imageUrl} alt="image" style={{ width: '100px', height: 'auto' }} />;
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <>
        <EditOutlined onClick={() => handleEditImage(record)} style={{ marginRight: 16 }} />
        <Popconfirm title="Are you sure to delete?" onConfirm={() => handleDeleteImage(record._id)}>
          <DeleteOutlined />
        </Popconfirm>
      </>
    ),
  },
];

  return (
    <div className="container">
      <Tabs defaultActiveKey="1" className="tabs">
        <TabPane tab="Add Vehicle" key="1">
          <Form form={vehicleForm} layout="vertical" onFinish={handleVehicleSubmit} className="form">
            <Form.Item
              name="vehicleName"
              label="Vehicle Name"
              rules={[{ required: true, message: 'Please enter the vehicle name' }]}
            >
              <Input placeholder="Enter vehicle name" />
            </Form.Item>
            <Form.Item
              name="numberOfPassengers"
              label="Number of Passengers"
              rules={[{ required: true, message: 'Please enter the number of passengers' }]}
            >
              <InputNumber min={1} max={100} placeholder="Enter number of passengers" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="vehiclePrice"
              label="Vehicle Price"
              rules={[{ required: true, message: 'Please enter the vehicle price' }]}
            >
              <InputNumber min={1} max={1000000} placeholder="Enter vehicle price" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="image"
              label="Image"
              valuePropName="fileList"
              getValueFromEvent={(e) => Array.isArray(e) ? e : e && e.fileList}
              rules={[{ required: true, message: 'Please upload an image' }]}
            >
              <Upload name="image" listType="picture" beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Add Vehicle
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Vehicle List" key="3">
                <Table
          columns={vehicleColumns}
          dataSource={vehicles} // Pass the array directly
          rowKey="_id" // Ensure unique key for each row
        />

        </TabPane>
        <TabPane tab="Price per Mile" key="4">
          <Form form={priceForm} layout="vertical" onFinish={handlePriceSubmit} className="form">
            <Form.Item
              name="pricePerKilometer"
              label="Price per Mile"
              initialValue={pricePerKilometer || 0} // Set initial value from state, fallback to 0 if null
              rules={[{ required: true, message: 'Please enter the price per mile' }]}
            >
              <InputNumber min={0} max={10000} placeholder="Enter price per kilometer" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Update Price
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Price per Hour" key="8">
          <Form form={pricePerHourForm} layout="vertical" onFinish={handlePricePerHourSubmit} className="form">
            <Form.Item
              name="pricePerHour"
              label="Price per Hour"
              initialValue={pricePerHour || 0} // Set initial value from state, fallback to 0 if null
              rules={[{ required: true, message: 'Please enter the price per hour' }]}
            >
              <InputNumber min={0} max={1000000} placeholder="Enter price per hour" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Update Price
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Manage Images" key="6">
  <Form layout="vertical" onFinish={handleImageSubmit}>
    <Form.Item
      name="image"
      label="Image"
      valuePropName="fileList"
      getValueFromEvent={(e) => Array.isArray(e) ? e : e && e.fileList}
      rules={[{ required: true, message: 'Please upload an image' }]}
    >
      <Upload name="image" listType="picture" beforeUpload={() => false}>
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
    <Form.Item
      name="description"
      label="Description"
      rules={[{ required: true, message: 'Please enter the description' }]}
    >
      <Input.TextArea placeholder="Enter description" />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
        Add Image
      </Button>
    </Form.Item>
  </Form>
  <Table
    columns={imageColumns}
    dataSource={images}
    rowKey="_id"
  />
</TabPane>



      </Tabs>

      <Modal
  title="Edit Vehicle"
  open={isVehicleModalVisible}
  onCancel={() => {
    setIsVehicleModalVisible(false);
    setEditingVehicle(null); // Reset editingVehicle when modal is closed
  }}
  footer={null}
>
  <Form
    layout="vertical"
    form={vehicleForm} // Ensure form instance is provided
    onFinish={handleVehicleUpdate}
  >
    <Form.Item
      name="vehicleName"
      label="Vehicle Name"
      rules={[{ required: true, message: 'Please enter the vehicle name' }]}
    >
      <Input placeholder="Enter vehicle name" />
    </Form.Item>
    <Form.Item
      name="numberOfPassengers"
      label="Number of Passengers"
      rules={[{ required: true, message: 'Please enter the number of passengers' }]}
    >
      <InputNumber min={1} max={100} placeholder="Enter number of passengers" style={{ width: '100%' }} />
    </Form.Item>
    <Form.Item
      name="vehiclePrice"
      label="Vehicle Price"
      rules={[{ required: true, message: 'Please enter the vehicle price' }]}
    >
      <InputNumber min={1} max={1000000} placeholder="Enter vehicle price" style={{ width: '100%' }} />
    </Form.Item>
    <Form.Item
      name="image"
      label="Image"
      valuePropName="fileList"
      getValueFromEvent={(e) => Array.isArray(e) ? e : e && e.fileList || []}
    >
      <Upload name="image" listType="picture" beforeUpload={() => false}>
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
        Update Vehicle
      </Button>
    </Form.Item>
  </Form>
</Modal>
<Modal
      title="Edit Image"
      open={isImageModalVisible}
      onCancel={() => {
        setIsImageModalVisible(false);
        setEditingImage(null); // Reset editingImage when modal is closed
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        form={imageForm}
        onFinish={handleImageUpdate}
        initialValues={{
          description: editingImage?.description || '',
          image: editingImage?.image ? [{ url: `https://johnwayneshuttle.com/uploads/${editingImage.image}` }] : [],
        }}
      >
        <Form.Item
          name="image"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={(e) => Array.isArray(e) ? e : e && e.fileList || []}
        >
          <Upload
            name="image"
            listType="picture"
            beforeUpload={() => false} // Prevent auto upload
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter a description' }]}
        >
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Update Image
          </Button>
        </Form.Item>
      </Form>
    </Modal>



    </div>
  );
};

export default VehicleAndRoute;
