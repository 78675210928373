import React, { useState,   useEffect, Spin } from "react";
import styled from "styled-components";
import { Drawer, Button, Form, Input, Select, message, Divider, Modal, InputNumber, DatePicker, TimePicker, Card, Row, Col,Alert  } from "antd";
import axios from "axios";
import { useAuth } from "../../../authcontext/AuthContext";
// Components
import ClientSlider from "../Elements/ClientSlider";
import { FaPlane, FaMapMarkerAlt, FaClock } from "react-icons/fa";
import { PayPalButtons } from "@paypal/react-paypal-js";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import "./service.css"
export default function Services() {
  const [selectedService, setSelectedService] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setDrawerVisible(true);
  };

  // Function to close the drawer
  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <Wrapper id="services">
      <div className="lightBg " style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            <p className="font13">
              Choose from one of our services below, and fill out the corresponding form to book.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceCard>
              <IconWrapper>
                <FaPlane size={40} className="serviceIcon"/>
              </IconWrapper>
              <h3>Airport Service</h3>
              <p>Book a car to or from the airport.</p>
              <StyledButton onClick={() => handleServiceClick("Airport Service")}>
                Book Now
              </StyledButton>
            </ServiceCard>
            <ServiceCard>
              <IconWrapper>
                <FaMapMarkerAlt size={40} className="serviceIcon" />
              </IconWrapper>
              <h3>Point to Point Service</h3>
              <p>Get picked up and dropped off at specific locations.</p>
              <StyledButton onClick={() => handleServiceClick("Point to Point Service")}>
                Book Now
              </StyledButton>
            </ServiceCard>
            <ServiceCard>
              <IconWrapper>
                <FaClock size={40}className="serviceIcon" />
              </IconWrapper>
              <h3>Hourly Charter</h3>
              <p>Book a car for a specific duration.</p>
              <StyledButton onClick={() => handleServiceClick("Hourly Charter")}>
                Book Now
              </StyledButton>
            </ServiceCard>
          </ServiceBoxRow>
        </div>
      </div>

      {/* Drawer component */}
      <Drawer
        title={selectedService ? `${selectedService} Booking Form` : ""}
        placement="right"
        onClose={onClose}
        open={drawerVisible}
        width={600}
      >
        {selectedService === "Airport Service" && <AirportServiceForm />}
        {selectedService === "Point to Point Service" && <PointToPointServiceForm />}
        {selectedService === "Hourly Charter" && <HourlyCharterForm />}

      </Drawer>
    </Wrapper>
  );
}


const californiaAirports = [
  { name: 'Los Angeles International Airport', code: 'LAX', lat: 33.942791, lon: -118.410042 },
  { name: 'John Wayne Airport', code: 'SNA', lat: 33.6756, lon: -117.8682 },
  { name: 'Ontario International Airport', code: 'ONT', lat: 34.056, lon: -117.6012 },
  { name: 'Long Beach Airport', code: 'LGB', lat: 33.817799, lon: -118.151596 },
  { name: 'Hollywood Burbank Airport', code: 'BOB', lat: 34.200667, lon: -118.357222 }
];

function AirportServiceForm() {
  const [form] = Form.useForm();
  const { isAuthenticated, login } = useAuth();
  const [tripType, setTripType] = useState("one-way");
  const [passengerCount, setPassengerCount] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [pricePerKm, setPricePerKm] = useState(null);
  const [vehicle, setVehicle] = useState("");
  const [isSelectionComplete, setIsSelectionComplete] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [guestInfo, setGuestInfo] = useState({
    fullName: '',
    email: '',
    phone: ''
  });

  const [showGuestOptions, setShowGuestOptions] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalDistance, setTotalDistance] = useState(0);
  const [pickupDate, setPickupDate] = useState(null);
  const [pickupTime, setPickupTime] = useState(null);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showGuestForm, setShowGuestForm] = useState(false);
  const [departureAirport, setDepartureAirport] = useState("");
  const [arrivalAirport, setArrivalAirport] = useState("");
  const [userDetails, setUserDetails] = useState({
    email: '',
    phone: '',
    fullName: '',
  });



  const [isGuestFormComplete, setIsGuestFormComplete] = useState(false);
  const [isServiceAvailable, setIsServiceAvailable] = useState(false);
  const [residenceSuggestions, setResidenceSuggestions] = useState([]);
  const [airportCoords, setAirportCoords] = useState({ lat: 0, lon: 0 });
  const [residenceCoords, setResidenceCoords] = useState({ lat: 0, lon: 0 });
  const [selectedAirport, setSelectedAirport] = useState("");
  const [distanceError, setDistanceError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
 useEffect(() => {
  const fetchPricingData = async () => {
    try {
      const [vehicleRes, pricePerKmRes] = await Promise.all([
        axios.get("https://johnwayneshuttle.com/api/vehicles"),
        axios.get("https://johnwayneshuttle.com/api/price-per-kilometer")
      ]);



      setVehicles(vehicleRes.data);
      setPricePerKm(pricePerKmRes.data?.price || 0);
    } catch (error) {
      console.error("Failed to fetch vehicles or pricing", error);
    }
  };

  fetchPricingData();
}, []);
useEffect(() => {
  if (airportCoords.lat && airportCoords.lon && residenceCoords.lat && residenceCoords.lon) {
    const distance = calculateDistance(
      airportCoords.lat,
      airportCoords.lon,
      residenceCoords.lat,
      residenceCoords.lon
    );

    setTotalDistance(distance);
    if (distance < 500) {
      setIsServiceAvailable(true);
      setDistanceError(false);
    } else {
      setIsServiceAvailable(false);
      setDistanceError(true);
    }
  }
}, [airportCoords, residenceCoords]);



useEffect(() => {
  if (isAuthenticated) {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token || isTokenExpired(token)) {
          console.error('Token is expired or not present. Please log in again.');

          return;
        }

        const response = await axios.get('https://johnwayneshuttle.com/api/users', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserDetails(response.data);
        console.log('User details fetcheds:', response.data);
      } catch (error) {
        console.error('Error fetching user details:', error.response?.data || error.message);
      }
    };

    const isTokenExpired = (token) => {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    };

    fetchUserDetails();
  }
}, [isAuthenticated]);
const handleResidenceSearch = (value) => {
  setSearchQuery(value);
  if (value) {
    fetchLocationSuggestions(value);
  } else {
    setResidenceSuggestions([]);
  }
};



const handleResidenceSelect = (residenceName) => {
  const residence = residenceSuggestions.find((r) => r.display_name === residenceName);
  setSearchQuery(residenceName);
  if (residence) {
    setResidenceCoords({ lat: residence.lat, lon: residence.lon });
  }
};

const handleAirportSelect = (airportName) => {
  const airport = californiaAirports.find((a) => a.name === airportName);
  if (airport) {
    setAirportCoords({ lat: airport.lat, lon: airport.lon });
  }
};
   useEffect(() => {
    const filterVehiclesByPassengers = () => {
      const filtered = vehicles.filter(v => v.numberOfPassengers >= passengerCount);
      setFilteredVehicles(filtered);
    };

    filterVehiclesByPassengers();
  }, [passengerCount, vehicles]);



  useEffect(() => {
    const filtered = vehicles.filter(v => v.numberOfPassengers >= passengerCount);
    setFilteredVehicles(filtered);
  }, [passengerCount, vehicles]);



  const fetchLocationSuggestions = async (query) => {
    try {
      const response = await axios.get(`https://johnwayneshuttle.com/api/locations?query=${query}`);
      setResidenceSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  };


  const onFinish = async (values) => {
    setLoading(true);

    if (!pickupDate || !pickupTime) {
      message.error("Please select both a pickup date and time.");
      return;
    }

    // Check if the trip type is round-trip
    const isRoundTrip = ["ride-from-round", "ride-to-round", "ride-from-two-airports"].includes(tripType);

    // Calculate the adjusted total price and round it to the nearest integer
    const adjustedTotalPrice = Math.round(isRoundTrip ? totalPrice * 2 : totalPrice); // Round the price to the nearest integer

    const bookingData = {
      ...values,
      serviceType: "Airport Service",
      vehicle: selectedVehicle.vehicleName,
      tripType,
      pickupDate: pickupDate.format("YYYY-MM-DD"),
      pickupTime: pickupTime.format("HH:mm"),
      userInfo: isAuthenticated
        ? {
            fullName: userDetails.fullName,
            email: userDetails.email,
            phone: userDetails.phone,
          }
        : {
            fullName: guestInfo.fullName,
            phone: guestInfo.phone,
            email: guestInfo.email,
          },
      totalPrice: adjustedTotalPrice,
      pricePerKm,
      totalDistance,
      paymentMethod,
      passengerCount,
      airportName: selectedAirport,
      departureAirport,
      arrivalAirport,
      residence: searchQuery,
      vehicleName: selectedVehicle.vehicleName,
      vehiclePrice: selectedVehicle.vehiclePrice,
    };

    try {
      const response = await axios.post("https://johnwayneshuttle.com/api/submit-booking", bookingData);
      console.log("Booking successful:", response.data);

      // Show success modal
      Modal.success({
        title: "Booking Successful!",
        content: (
          <div>
            <p><strong>Full Name:</strong> {isAuthenticated ? userDetails.fullName : guestInfo.fullName}</p>
            <p><strong>Email:</strong> {isAuthenticated ? userDetails.email : guestInfo.email}</p>
            <p><strong>Service type:</strong> {bookingData.serviceType}</p>
            <p><strong>Airport Name:</strong> {selectedAirport}</p>
            {tripType === "ride-from-two-airports" ? (
              <>
                <p><strong>Departure Airport:</strong> {departureAirport}</p>
                <p><strong>Arrival Airport:</strong> {arrivalAirport}</p>
              </>
            ) : (
              <p><strong>Selected Airport:</strong> {selectedAirport}</p>
            )}
            <p><strong>Residence:</strong> {searchQuery}</p>
            <p><strong>Pickup Date:</strong> {bookingData.pickupDate}</p>
            <p><strong>Pickup Time:</strong> {bookingData.pickupTime}</p>
            <p><strong>Amount:</strong> ${adjustedTotalPrice}</p> {/* Adjusted price without decimals */}
            <p><strong>Payment Method:</strong> {paymentMethod}</p>
            <p><strong>Vehicle:</strong> {bookingData.vehicleName}</p>
          </div>
        ),
        onOk: () => {
          // Reload the page when the modal is closed
          window.location.reload();
        },
      });

      // Reset form fields
      form.resetFields();
      resetForm();
    } catch (error) {
      console.error("Failed to submit booking. Please try again:", error);
      message.error("Failed to submit booking. Please try again.");
    } finally {
      setLoading(false);
    }

    console.log("Booking Data:", bookingData);
  };



const resetForm = () => {
  setPassengerCount(1);
  setTotalDistance(0);
  setVehicle("");
  setShowSummary(false);
  setShowGuestOptions(false);
  setTotalPrice(0);
  setPaymentMethod("");
  setGuestInfo({  fullName: '', email: '', phone: '' });
  setDepartureAirport("");
  setArrivalAirport("");
  setSelectedAirport("");
  setDistanceError(false);
  setResidenceSuggestions([]);
  setSearchQuery("");
  setAirportCoords({ lat: 0, lon: 0 });
  setResidenceCoords({ lat: 0, lon: 0 });
  setFilteredVehicles([]);
  setTripType("one-way");
  setPickupDate(null);
  setPickupTime(null);
  setSelectedVehicle(null);
  setIsSelectionComplete(false);
  setIsGuestFormComplete(false);
  setIsPaymentCompleted(false);

};


const handleTripTypeChange = (value) => {
  setTripType(value);
  setSelectedAirport("");
  setDepartureAirport("");
  setArrivalAirport("");
  setTotalDistance(0);
};

  const handlePassengerCountChange = (value) => {
    setPassengerCount(value);

      const suitableVehicles = vehicles.filter(v => v.numberOfPassengers >= value);

      if (suitableVehicles.length === 0) {
        message.error(`No vehicles available for ${value} passengers. Please reduce the number of passengers.`);
      } else {
        message.success(`Vehicles available for ${value} passengers.`);
      }
  };



  const checkSelectionComplete = (pickup, dropoff, vehicle) => {
    setIsSelectionComplete(!!(pickup && dropoff && vehicle));
  };

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };



  const handlePayPalSuccess = async (data, actions) => {
    setLoading(true);
    try {
      const order = await actions.order.capture();
      console.log("PayPal payment successful:", order);
      setIsPaymentCompleted(true);

      const paypalTransactionId = order.id;
      const paypalPayerEmail = order.payer.email_address;

      // Check if the trip type is round-trip
      const isRoundTrip = ["ride-from-round", "ride-to-round", "ride-from-two-airports"].includes(tripType);

      // Calculate the adjusted total price and round it to the nearest integer
      const adjustedTotalPrice = Math.round(isRoundTrip ? totalPrice * 2 : totalPrice); // Round the price to the nearest integer

      const bookingData = {
        serviceType: "Airport Service",
        vehicle,
        tripType,
        pickupDate: pickupDate.format("YYYY-MM-DD"),
        pickupTime: pickupTime.format("HH:mm"),
        userInfo: isAuthenticated
          ? {
              fullName: userDetails.fullName,
              email: userDetails.email,
              phone: userDetails.phone,
            }
          : {
              fullName: guestInfo.fullName,
              phone: guestInfo.phone,
              email: guestInfo.email,
            },
        distance: totalDistance,
        departureAirport,
        arrivalAirport,
        airportName: selectedAirport,
        totalPrice: adjustedTotalPrice,
        pricePerKm,
        totalDistance,
        paymentMethod,
        passengerCount,
        vehicleName: vehicle,
        paypalTransactionId,
        paypalPayerEmail,
      };

      try {
        const response = await axios.post(
          "https://johnwayneshuttle.com/api/submit-booking",
          bookingData
        );
        console.log("Booking successful:", response.data);

        // Show success modal
        Modal.success({
          title: "Booking Successful!",
          content: (
            <div>
              <p><strong>Full Name: </strong>{isAuthenticated ? userDetails.fullName : guestInfo.fullName}</p>
              <p><strong>Email: </strong>{isAuthenticated ? userDetails.email : guestInfo.email}</p>
              <p><strong>Service Type: </strong>{bookingData.serviceType}</p>
              <p><strong>Amount Paid:</strong> ${adjustedTotalPrice}</p> {/* Adjusted and rounded price */}
              <p><strong>Payment Method:</strong> {paymentMethod}</p>
              <p><strong>PayPal Transaction ID: </strong>{paypalTransactionId}</p>
              <p><strong>PayPal Payer Email:</strong> {paypalPayerEmail}</p>
            </div>
          ),
          onOk: () => {
            // Reload the page when the modal is closed
            window.location.reload();
          },
        });

        form.resetFields();
        setPassengerCount(1);
        setTotalDistance(0);
        setVehicle("");
        setShowGuestOptions(false);
        setShowSummary(false);
      } catch (error) {
        console.error("Error booking hourly charter:", error);
        message.error("Failed to submit booking. Please try again.");
      }
    } catch (error) {
      console.error("PayPal payment failed:", error);
      message.error("PayPal payment failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };




  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 3959;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};



  const handleLogin = async (values) => {
    try {
      await login(values.username, values.password);
      setLoginVisible(false);
      setShowSummary(true);
      setShowGuestOptions(false);
    } catch (error) {
      console.error("Login failed:", error);
      message.error("Login failed. Please try again.");
    }
  };

  useEffect(() => {
    if (pickupDate && pickupTime) {
      if (!isAuthenticated) {
        setShowGuestOptions(true);
      } else {
        setShowSummary(true);
      }
    }
  }, [pickupDate, pickupTime, isAuthenticated]);

  useEffect(() => {
    if (guestInfo.fullName && guestInfo.phone && guestInfo.email) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [guestInfo]);

  useEffect(() => {
    if (isGuestFormComplete) {
      setShowSummary(true);
    }
  }, [isGuestFormComplete]);

   const handlePickupDateChange = (date) => {
    setPickupDate(date);
    checkSelectionComplete();
  };

  const handlePickupTimeChange = (time) => {
    setPickupTime(time);
    checkSelectionComplete();
  };

const disabledDateTime = () => {
  const now = moment();


  if (selectedDate && moment(selectedDate).isSame(now, 'day')) {
    return {

      disabledHours: () => {
        const hours = [];
        for (let i = 0; i < now.hour(); i++) {
          hours.push(i);
        }
        return hours;
      },

      disabledMinutes: (selectedHour) => {
        if (selectedHour === now.hour()) {
          const minutes = [];
          for (let i = 0; i <= now.minute(); i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      },
    };
  }

  return {
    disabledHours: () => [],
    disabledMinutes: () => [],
  };
};
  const handleVehicleSelect = (vehicle) => {
    setSelectedVehicle(vehicle);
    const price = calculateTotalPrice(vehicle, totalDistance);
    setTotalPrice(price);
    console.log("Selected Vehicle:", vehicle);
};

  const calculateTotalPrice = (vehicle, distance) => {
    if (!vehicle || distance === 0) return 0;
    const { vehiclePrice } = vehicle;
    const calculatedPrice = (distance * pricePerKm) + vehiclePrice;
    return calculatedPrice;
};

useEffect(() => {
  if (selectedVehicle) {
      setTotalPrice(calculateTotalPrice(selectedVehicle, totalDistance));
  }
}, [totalDistance, selectedVehicle]);




const isVehicleSelected = (vehicle) => {
  return selectedVehicle && selectedVehicle._id === vehicle._id;
};
useEffect(() => {
  if (pickupDate && pickupTime) {
    if (!isAuthenticated) {
      setShowGuestOptions(true);
    } else {
      setShowSummary(true);
    }
  }
}, [pickupDate, pickupTime, isAuthenticated]);
const handleContinueAsGuest = () => {
  setShowGuestForm(true);
  setShowGuestOptions(false);
  setLoginVisible(false);
};



const handleLoginOption = () => {
  setLoginVisible(true);
  setShowGuestForm(false);
};
const handleGuestFormSubmit = (values) => {
  setGuestInfo(values);
  setIsGuestFormComplete(true);
  setShowSummary(true);
};
useEffect(() => {
  if (isGuestFormComplete || isAuthenticated) {
    if (pickupDate && pickupTime ) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }
}, [isGuestFormComplete, isAuthenticated, pickupDate, pickupTime]);

const emailValidation = [
  {
    required: true,
    message: 'Please enter your email',
  },
  {
    type: 'email',
    message: 'The input is not a valid email!',
  },
];
return (
  <Form form={form} layout="vertical" onFinish={onFinish}>
    <Form.Item label="Trip Type">
      <Select value={tripType} onChange={handleTripTypeChange} placeholder="Select Trip Type">
        <Select.Option value="ride-from-one-way">Ride From Airport (One-Way)</Select.Option>
        <Select.Option value="ride-from-round">Ride From Airport (Round Trip)</Select.Option>
        <Select.Option value="ride-to-one-way">Ride To Airport (One-Way)</Select.Option>
        <Select.Option value="ride-to-round">Ride To Airport (Round Trip)</Select.Option>
        <Select.Option value="ride-from-two-airports">Ride From Airport (Round Trip with Two Airports)</Select.Option>
      </Select>
    </Form.Item>

    {tripType === "ride-from-two-airports" ? (
      <>
        <Form.Item label="Departure Airport">
          <Select
            value={departureAirport}
            onChange={(airportName) => {
              setDepartureAirport(airportName);
              handleAirportSelect(airportName);
            }}
            placeholder="Select Departure Airport"
          >
            {californiaAirports.map((airport) => (
              <Select.Option key={airport.code} value={airport.name}>
                {airport.name} - {airport.code}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Arrival Airport">
          <Select
            value={arrivalAirport}
            onChange={(airportName) => {
              setArrivalAirport(airportName);
              handleAirportSelect(airportName);
            }}
            placeholder="Select Arrival Airport"
          >
            {californiaAirports.map((airport) => (
              <Select.Option key={airport.code} value={airport.name}>
                {airport.name} - {airport.code}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </>
    ) : (
      <Form.Item label="Select Airport">
        <Select
          value={selectedAirport}
          onChange={(airportName) => {
            setSelectedAirport(airportName);
            handleAirportSelect(airportName);
          }}
          placeholder="Select Airport"
        >
          {californiaAirports.map((airport) => (
            <Select.Option key={airport.code} value={airport.name}>
              {airport.name} - {airport.code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    )}

    {/* Conditionally render the residence input */}
    {tripType !== "ride-from-two-airports" && (
      <Form.Item label="Enter Hotel/ Residence/ Other" name="residence" rules={[{ required: true }]}>
        <Select
          showSearch
          value={searchQuery}
          onSearch={handleResidenceSearch}
          onChange={(residenceName) => {
            setSearchQuery(residenceName);
            handleResidenceSelect(residenceName);
          }}
          placeholder="Enter Hotel/ Residence/ Other"
          filterOption={false}
          notFoundContent={searchQuery ? "No matches found" : null}
        >
          {residenceSuggestions.map((residence) => (
            <Select.Option key={residence.display_name} value={residence.display_name}>
              {residence.display_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    )}
  {distanceError && (
        <div style={{ color: 'red', marginBottom: '16px' }}>
          The distance exceeds 500 mile. Please choose a different residence or airport.
        </div>
      )}
    <Form.Item label="Number of Passengers" name="passengerCount" rules={[{ required: true }]}>
      <InputNumber
        min={1}
        max={100}
        placeholder="Number of Passengers"
        onChange={(value) => handlePassengerCountChange(value)}
        disabled={!isServiceAvailable}
      />
    </Form.Item>

    {filteredVehicles.length > 0 && (
  <div>
    <h3>Select a Vehicle</h3>
    <Row gutter={16}>
  {filteredVehicles.map((v) => {
    // Check if the trip type is round-trip
    const isRoundTrip = ["ride-from-round", "ride-to-round", "ride-from-two-airports"].includes(tripType);

    // Calculate the adjusted total price
    const adjustedTotalPrice = Math.round(isRoundTrip ? calculateTotalPrice(v, totalDistance) * 2 : calculateTotalPrice(v, totalDistance));

    return (
      <Col
        key={v._id}
        xs={24}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        className="vehicle-list"
      >
        <Card
          hoverable
          cover={<img alt={v.vehicleName} src={`https://johnwayneshuttle.com/uploads/${v.image}`} />}
          onClick={() => handleVehicleSelect(v)}
          style={{
            border: isVehicleSelected(v) ? "2px solid #1890ff" : "1px solid #d9d9d9",
            boxShadow: isVehicleSelected(v) ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none",
          }}
          className="vehicle-card"
        >
          <Card.Meta
            title={<div className="vehicle-card-title">{`${v.vehicleName} - ${v.numberOfPassengers} Passengers`}</div>}
            description={`Total Price: $${adjustedTotalPrice.toFixed(2)}`}
          />
        </Card>
      </Col>
    );
  })}
</Row>

  </div>
)}








    {/* Show Pickup Date and Time after vehicle is selected */}
    {selectedVehicle && (
      <>

        <Form.Item label="Pickup Date" name="pickupDate" rules={[{ required: true }]}>
          <DatePicker
            disabledDate={(current) => current && current < moment().startOf("day")}
            onChange={handlePickupDateChange}
          />
        </Form.Item>


        <Form.Item label="Pickup Time" name="pickupTime" rules={[{ required: true }]}>
  <TimePicker
    format="h:mm A"
    onChange={handlePickupTimeChange}

    use12Hours
  />
</Form.Item>
      </>
    )}

    {showGuestForm && (
      <Form layout="vertical" onFinish={handleGuestFormSubmit}>
        <Form.Item label="Full Name" name="fullName" rules={[{ required: true }]}>
          <Input onChange={(e) => setGuestInfo({ ...guestInfo, fullName: e.target.value })} autoComplete="new-password"/>
        </Form.Item>
        <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
          <Input onChange={(e) => setGuestInfo({ ...guestInfo, phone: e.target.value })} autoComplete="new-password"/>
        </Form.Item>
        <Form.Item label="Email" name="email" rules={emailValidation}>
          <Input onChange={(e) => setGuestInfo({ ...guestInfo, email: e.target.value })} autoComplete="new-password"/>
        </Form.Item>
      </Form>
    )}

    {!isAuthenticated && (
      <>
        {showGuestOptions ? (
          <>
          <div className="guest-options">
            <Button onClick={handleLoginOption}>Login</Button>
            <Button onClick={handleContinueAsGuest}>Continue as Guest</Button>
            </div>
          </>
        ) : null}
      </>
    )}
{showSummary && (
  <div>
    <h4>Summary</h4>
    <p><strong>Trip Type:</strong> {tripType}</p>
    <p><strong>Passenger Count:</strong> {passengerCount}</p>
    {tripType === "ride-from-two-airports" ? (
      <>
        <p><strong>Departure Airport:</strong> {departureAirport}</p>
        <p><strong>Arrival Airport:</strong> {arrivalAirport}</p>
      </>
    ) : (
      <p><strong>Selected Airport:</strong> {selectedAirport}</p>
    )}

    <p><strong>Vehicle:</strong> {selectedVehicle?.vehicleName}</p>

    {/* Format the total distance to 1 decimal place */}
    <p><strong>Total Distance:</strong> {totalDistance.toFixed(1)} miles</p>

    {/* Calculate and display the adjusted total price without decimal */}
    <p>
      <strong>Total Price:</strong> $
      {(
        ["ride-from-round", "ride-to-round", "ride-from-two-airports"].includes(tripType)
          ? totalPrice * 2 // Multiply the price by 2 for round trips
          : totalPrice
      ).toFixed(0)} {/* Round the price to the nearest integer */}
    </p>

    <p>
      <strong>{isAuthenticated ? "User Info" : "Guest Info"}:</strong>
      {isAuthenticated && userDetails
        ? `Full Name: ${userDetails.fullName}, Email: ${userDetails.email}, Phone: ${userDetails.phone}`
        : `${guestInfo.fullName}, ${guestInfo.phone}, ${guestInfo.email}`}
    </p>

    <Form.Item label="Payment Method">
      <Select
        value={paymentMethod}
        onChange={handlePaymentMethodChange}
        placeholder="Select Payment Method"
      >
        <Select.Option value="paypal">PayPal</Select.Option>
        <Select.Option value="pay-after-service">Pay after service</Select.Option>
      </Select>
    </Form.Item>

    {paymentMethod === "paypal" && (
      <div>
        <PayPalButtons
          createOrder={(data, actions) => {
            const amount = Math.round(
              ["ride-from-round", "ride-to-round", "ride-from-two-airports"].includes(tripType)
                ? totalPrice * 2
                : totalPrice
            );

            return actions.order.create({
              purchase_units: [
                {
                  amount: { value: amount },
                },
              ],
            });
          }}
          onApprove={handlePayPalSuccess}
        />
      </div>
    )}

    {paymentMethod === "pay-after-service" && (
      <Button type="primary" htmlType="submit" loading={loading}>
        Submit
      </Button>
    )}
  </div>
)}



    <Modal
      title="Login"
      open={loginVisible}
      onCancel={() => setLoginVisible(false)}
      footer={null}
    >
      <Form layout="vertical" onFinish={handleLogin}>
        <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password />
        </Form.Item>
        <Button type="primary" htmlType="submit">Login</Button>
      </Form>
    </Modal>
  </Form>
);


}

function PointToPointServiceForm() {
  const [form] = Form.useForm();
  const { isAuthenticated, login } = useAuth();
  const [tripType, setTripType] = useState("one-way");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [passengerCount, setPassengerCount] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [pricePerKm, setPricePerKm] = useState(null);
  const [vehicle, setVehicle] = useState("");
  const [isSelectionComplete, setIsSelectionComplete] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [guestInfo, setGuestInfo] = useState({
    fullName: '',
    email: '',
    phone: ''
  });

  const [showGuestOptions, setShowGuestOptions] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalDistance, setTotalDistance] = useState(0);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [tripDuration, setTripDuration] = useState();
  const [pickupDate, setPickupDate] = useState(null);
  const [pickupTime, setPickupTime] = useState(null);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showGuestForm, setShowGuestForm] = useState(false);
  const [pricePerHour, setPricePerHour] = useState(null);
  const [userDetails, setUserDetails] = useState({
    email: '',
    phone: '',
    fullName: '',
  });



  const [isGuestFormComplete, setIsGuestFormComplete] = useState(false);
  const [isServiceAvailable, setIsServiceAvailable] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [loading, setLoading] = useState(false);
 useEffect(() => {
  const fetchPricingData = async () => {
    try {
      const [vehicleRes, pricePerKmRes] = await Promise.all([
        axios.get("https://johnwayneshuttle.com/api/vehicles"),
        axios.get("https://johnwayneshuttle.com/api/price-per-kilometer")
      ]);



      setVehicles(vehicleRes.data);
      setPricePerKm(pricePerKmRes.data?.price || 0);
    } catch (error) {
      console.error("Failed to fetch vehicles or pricing", error);
    }
  };

  fetchPricingData();
}, []);


useEffect(() => {
  if (isAuthenticated) {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token || isTokenExpired(token)) {
          console.error('Token is expired or not present. Please log in again.');

          return;
        }

        const response = await axios.get('https://johnwayneshuttle.com/api/users', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserDetails(response.data);
        console.log('User details fetcheds:', response.data);
      } catch (error) {
        console.error('Error fetching user details:', error.response?.data || error.message);
      }
    };

    const isTokenExpired = (token) => {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    };

    fetchUserDetails();
  }
}, [isAuthenticated]);

   useEffect(() => {
    const filterVehiclesByPassengers = () => {
      const filtered = vehicles.filter(v => v.numberOfPassengers >= passengerCount);
      setFilteredVehicles(filtered);
    };

    filterVehiclesByPassengers();
  }, [passengerCount, vehicles]);

  useEffect(() => {
    if (pickupLocation && dropoffLocation && passengerCount > 0) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [pickupLocation, dropoffLocation, passengerCount]);

  useEffect(() => {
    const filtered = vehicles.filter(v => v.numberOfPassengers >= passengerCount);
    setFilteredVehicles(filtered);
  }, [passengerCount, vehicles]);

  useEffect(() => {
    if (pickupLocation && dropoffLocation && selectedVehicle) {
      const distance = calculateDistance(
        pickupLocation.lat,
        pickupLocation.lon,
        dropoffLocation.lat,
        dropoffLocation.lon
      );
      setTotalDistance(distance);

      console.log("Distance:", distance);
      console.log("Selected Vehicle:", selectedVehicle);

      if (pricePerKm !== null && selectedVehicle) {
        const distancePrice = distance * pricePerKm;
        const vehiclePrice = selectedVehicle.vehiclePrice;

        console.log("Distance Price:", distancePrice);
        console.log("Vehicle Price:", vehiclePrice);

        if (!isNaN(distancePrice) && !isNaN(vehiclePrice)) {
          const totalPrice = distancePrice + vehiclePrice;
          console.log("Total Price Calculated:", totalPrice);
          setTotalPrice(totalPrice);
        } else {
          console.error("Invalid price calculation inputs");
          setTotalPrice(0);
        }
      }
    }
  }, [pickupLocation, dropoffLocation, tripType, pricePerKm, pricePerHour, selectedVehicle]);


  const onFinish = async (values) => {
    setLoading(true);
    if (!pickupDate || !pickupTime) {
      message.error("Please select both a pickup date and time.");
      return;
    }

    // Round the totalPrice and format the totalDistance
    const roundedTotalPrice = Math.round(totalPrice);  // Round price to nearest integer
    const formattedTotalDistance = totalDistance.toFixed(1);  // Format distance to 1 decimal place

    const bookingData = {
      ...values,
      serviceType: "Point to Point",
      vehicle: selectedVehicle.vehicleName,
      tripType,
      pickupDate: pickupDate.format("YYYY-MM-DD"),
      pickupTime: pickupTime.format("HH:mm"),
      userInfo: isAuthenticated
        ? {
            fullName: userDetails.fullName,
            email: userDetails.email,
            phone: userDetails.phone,
          }
        : {
            fullName: guestInfo.fullName,
            phone: guestInfo.phone,
            email: guestInfo.email,
          },
      totalPrice: roundedTotalPrice,
      pricePerKm,
      totalDistance: formattedTotalDistance,
      paymentMethod,
      pickupLocation,
      dropoffLocation,
      passengerCount,
      vehicleName: selectedVehicle.vehicleName,
      vehiclePrice: selectedVehicle.vehiclePrice,
    };

    try {
      const response = await axios.post(
        "https://johnwayneshuttle.com/api/submit-booking",
        bookingData
      );
      console.log("Booking successful:", response.data);

      // Show success modal
      Modal.success({
        title: "Booking Successful!",
        content: (
          <div>
            <p><strong>Full Name: </strong> {isAuthenticated ? userDetails.fullName : guestInfo.fullName}</p>
            <p><strong>Email: </strong>{isAuthenticated ? userDetails.email : guestInfo.email}</p>
            <p><strong>Service Type: </strong>{bookingData.serviceType}</p>

            <p><strong>Pickup Location: </strong>{pickupLocation.display_name}</p>
            <p><strong>Dropoff Location: </strong> {dropoffLocation.display_name}</p>

            <p><strong>Pickup Date: </strong> {bookingData.pickupDate}</p>
            <p><strong>Pickup Time: </strong> {bookingData.pickupTime}</p>
            <p><strong>Amount: </strong> ${roundedTotalPrice}</p>
            <p><strong>Payment Method</strong>{paymentMethod}</p>
            <p>Vehicle: {bookingData.vehicleName}</p>
          </div>
        ),
        onOk: () => {
          // Reload the page when the modal is closed
          window.location.reload();
        },
      });

      // Reset form fields
      form.resetFields();
      resetForm();
    } catch (error) {
      console.error("Error booking point-to-point service:", error);
      message.error("Failed to submit booking. Please try again.");
    } finally {
      setLoading(false);
    }

    console.log("Booking Data:", bookingData);
  };


const resetForm = () => {
  setPassengerCount(1);
  setPickupLocation("");
  setDropoffLocation("");
  setTotalDistance(0);
  setVehicle("");
  setShowGuestOptions(false);
  setShowSummary(false);
  setTripDuration(0);
  setTotalPrice(0);
  form.resetFields();

};


  const handleTripTypeChange = (value) => {
    setTripType(value);
    setPickupLocation("");
    setDropoffLocation("");
    setTotalDistance(0);
  };

  const handlePassengerCountChange = (value) => {
    setPassengerCount(value);

      const suitableVehicles = vehicles.filter(v => v.numberOfPassengers >= value);

      if (suitableVehicles.length === 0) {
        message.error(`No vehicles available for ${value} passengers. Please reduce the number of passengers.`);
      } else {
        message.success(`Vehicles available for ${value} passengers.`);
      }
  };



  const checkSelectionComplete = (pickup, dropoff, vehicle) => {
    setIsSelectionComplete(!!(pickup && dropoff && vehicle));
  };

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };

  const handlePayPalSuccess = async (data, actions) => {
    setLoading(true);
    try {
      const order = await actions.order.capture();
      console.log("PayPal payment successful:", order);
      setIsPaymentCompleted(true);

      const paypalTransactionId = order.id;
      const paypalPayerEmail = order.payer.email_address;

      // Round the totalPrice and format the totalDistance
      const roundedTotalPrice = Math.round(totalPrice);  // Round price to nearest integer
      const formattedTotalDistance = totalDistance.toFixed(1);  // Format distance to 1 decimal place

      const bookingData = {
        serviceType: "Point to Point",
        vehicle,
        tripType,
        pickupDate: pickupDate.format("YYYY-MM-DD"),
        pickupTime: pickupTime.format("HH:mm"),
        userInfo: isAuthenticated
          ? {
              fullName: userDetails.fullName,
              email: userDetails.email,
              phone: userDetails.phone,
            }
          : {
              fullName: guestInfo.fullName,
              phone: guestInfo.phone,
              email: guestInfo.email,
            },
        totalPrice: roundedTotalPrice,
        pricePerKm,
        distance: formattedTotalDistance,
        paymentMethod,
        pickupLocation,
        dropoffLocation,
        passengerCount,
        vehicleName: vehicle,
        paypalTransactionId,
        paypalPayerEmail,
      };

      try {
        const response = await axios.post(
          "https://johnwayneshuttle.com/api/submit-booking",
          bookingData
        );
        console.log("Booking successful:", response.data);

        // Show success modal
        Modal.success({
          title: "Booking Successful with PayPal!",
          content: (
            <div>
              <p><strong>Full Name: </strong> {isAuthenticated ? userDetails.fullName : guestInfo.fullName}</p>
              <p><strong>Email: </strong> {isAuthenticated ? userDetails.email : guestInfo.email}</p>
              <p><strong>Service Type: </strong> {bookingData.serviceType}</p>

              <p><strong>Pickup Location: </strong>{pickupLocation.display_name}</p>
              <p><strong>Dropoff Location: </strong> {dropoffLocation.display_name}</p>

              <p><strong>Pickup Date: </strong> {bookingData.pickupDate}</p>
              <p><strong>Pickup Time: </strong> {bookingData.pickupTime}</p>
              <p><strong>Amount Paid: </strong> ${roundedTotalPrice}</p>
              <p><strong>Payment Method: </strong> {paymentMethod}</p>
              <p><strong>Vehicle: </strong> {bookingData.vehicleName}</p>
              <p><strong>PayPal Transaction ID: </strong> {paypalTransactionId}</p>
              <p><strong>PayPal Payer Email: </strong>{paypalPayerEmail}</p>
            </div>
          ),
          onOk: () => {
            // Reload the page when the modal is closed
            window.location.reload();
          },
        });

        // Reset form fields
        form.resetFields();
        setPassengerCount(1);
        setPickupLocation("");
        setDropoffLocation("");
        setTotalDistance(0);
        setVehicle("");
        setShowGuestOptions(false);
        setShowSummary(false);
      } catch (error) {
        console.error("Error booking point-to-point service:", error);
        message.error("Failed to submit booking. Please try again.");
      }
    } catch (error) {
      console.error("PayPal payment failed:", error);
      message.error("PayPal payment failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };



  const fetchLocationSuggestions = async (query) => {
    try {
      const response = await axios.get(`https://johnwayneshuttle.com/api/locations?query=${query}`);
      setLocationSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  };

   useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedQuery) {
        fetchLocationSuggestions(debouncedQuery);
      } else {
        setLocationSuggestions([]);
      }
    }, 300);

    return () => clearTimeout(handler);
  }, [debouncedQuery]);
   useEffect(() => {
    setDebouncedQuery(searchQuery);
  }, [searchQuery]);

  const handlePickupSearch = (value) => {
    setSearchQuery(value);
    if (value) {
      fetchLocationSuggestions(value);
    } else {
      setLocationSuggestions([]);
    }
  };

  const handlePickupSelect = (locationName) => {
    const location = locationSuggestions.find((loc) => loc.display_name === locationName);
    if (location) {
      setPickupLocation(location);
    }
    checkAvailability(location, dropoffLocation);
  };

 const handleDropoffSelect = (locationName) => {
  const location = locationSuggestions.find((loc) => loc.display_name === locationName);
  if (location) {
    setDropoffLocation(location);
  }
  checkAvailability(pickupLocation, location);
};
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3959;
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};


  const handleLogin = async (values) => {
    try {
      await login(values.username, values.password);
      setLoginVisible(false);
      setShowSummary(true);
      setShowGuestOptions(false);
    } catch (error) {
      console.error("Login failed:", error);
      message.error("Login failed. Please try again.");
    }
  };

  useEffect(() => {
    if (pickupDate && pickupTime) {
      if (!isAuthenticated) {
        setShowGuestOptions(true);
      } else {
        setShowSummary(true);
      }
    }
  }, [pickupDate, pickupTime, isAuthenticated]);

  useEffect(() => {
    if (guestInfo.fullName && guestInfo.phone && guestInfo.email) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [guestInfo]);

  useEffect(() => {
    if (isGuestFormComplete) {
      setShowSummary(true);
    }
  }, [isGuestFormComplete]);

   const handlePickupDateChange = (date) => {
    setPickupDate(date);
    checkSelectionComplete();
  };

  const handlePickupTimeChange = (time) => {
    setPickupTime(time);
    checkSelectionComplete();
  };


  const disabledDateTime = (pickupDate) => {
    const now = moment();

    if (pickupDate && pickupDate.isSame(now, 'day')) {
        return {
            disabledHours: () => {
                const hours = [];
                for (let i = 0; i < now.hour(); i++) {
                    hours.push(i);
                }
                return hours;
            },

            disabledMinutes: (selectedHour) => {
                if (selectedHour === now.hour()) {
                    const minutes = [];
                    for (let i = 0; i <= now.minute(); i++) {
                        minutes.push(i);
                    }
                    return minutes;
                }
                return [];
            },
        };
    }

    return {};
  };
const handleVehicleSelect = (vehicle) => {
  setSelectedVehicle(vehicle);
  console.log("Selected Vehicle:", vehicle);
};


const isVehicleSelected = (vehicle) => {
  return selectedVehicle && selectedVehicle._id === vehicle._id;
};
useEffect(() => {
  if (pickupDate && pickupTime) {
    if (!isAuthenticated) {
      setShowGuestOptions(true);
    } else {
      setShowSummary(true);
    }
  }
}, [pickupDate, pickupTime, isAuthenticated]);
const handleContinueAsGuest = () => {
  setShowGuestForm(true);
  setShowGuestOptions(false);
  setLoginVisible(false);
};



const handleLoginOption = () => {
  setLoginVisible(true);
  setShowGuestForm(false);
};
const handleGuestFormSubmit = (values) => {
  setGuestInfo(values);
  setIsGuestFormComplete(true);
  setShowSummary(true);
};
useEffect(() => {
  if (isGuestFormComplete || isAuthenticated) {
    if (pickupDate && pickupTime && pickupLocation && dropoffLocation) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }
}, [isGuestFormComplete, isAuthenticated, pickupDate, pickupTime, pickupLocation, dropoffLocation]);
 const calculatePriceForVehicle = (vehicle) => {
  if (pickupLocation && dropoffLocation) {
    const distance = calculateDistance(
      pickupLocation.lat,
      pickupLocation.lon,
      dropoffLocation.lat,
      dropoffLocation.lon
    );

    const distancePrice = distance * pricePerKm;
    const vehiclePrice = vehicle.vehiclePrice;
    return distancePrice + vehiclePrice;
  }
  return 0;
};

const checkAvailability = (pickup, dropoff) => {
  if (pickup && dropoff) {
    const distance = calculateDistance(pickup.lat, pickup.lon, dropoff.lat, dropoff.lon);

    if (distance > 500) {
      setIsServiceAvailable(false);
      message.error("Service is not available for distances greater than 500 miles. Please select a different location.");
    } else {
      setIsServiceAvailable(true);
      message.success("Service is available for your selected locations.");
    }
  }
};

const emailValidation = [
  {
    required: true,
    message: 'Please enter your email',
  },
  {
    type: 'email',
    message: 'The input is not a valid email!',
  },
];

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
    <Form.Item label="Trip Type">
      <Select value={tripType} onChange={handleTripTypeChange} placeholder="Select Trip Type">
        <Select.Option value="one-way">One-Way</Select.Option>
        <Select.Option value="round-trip">Round Trip</Select.Option>
      </Select>
    </Form.Item>

    <Form.Item label="Pickup Location" name="pickupLocation" rules={[{ required: true }]}>
      <Select
        showSearch
        value={searchQuery}
        onSearch={handlePickupSearch}
        onChange={(locationName) => {
          setSearchQuery(locationName);
          handlePickupSelect(locationName);
        }}
        placeholder="Enter Pickup Location"
        filterOption={false}
        notFoundContent={searchQuery ? "No matches found" : null}
      >
        {locationSuggestions.map((location) => (
          <Select.Option key={location.display_name} value={location.display_name}>
            {location.display_name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>

    <Form.Item label="Dropoff Location" name="dropoffLocation" rules={[{ required: true }]}>
      <Select
        showSearch
        value={searchQuery}
        onSearch={handlePickupSearch}
        onChange={(locationName) => {
          setSearchQuery(locationName);
          handleDropoffSelect(locationName);
        }}
        placeholder="Enter Dropoff Location"
        filterOption={false}
        notFoundContent={searchQuery ? "No matches found" : null}
      >
        {locationSuggestions.map((location) => (
          <Select.Option key={location.display_name} value={location.display_name}>
            {location.display_name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>


    <Form.Item label="Number of Passengers" name="passengerCount" rules={[{ required: true }]}>
      <InputNumber
        min={1}
        max={100}
        placeholder="Number of Passengers"
        onChange={(value) => handlePassengerCountChange(value)}
        disabled={!isServiceAvailable}
      />
    </Form.Item>

  {isFormComplete && (
    <div>
    <h3>Select a Vehicle</h3>
    <Row gutter={16}>
      {filteredVehicles.map((v) => (
        <Col key={v._id}
        xs={24}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        className="vehicle-list">
          <Card
            hoverable
            cover={<img alt={v.vehicleName} src={`https://johnwayneshuttle.com/uploads/${v.image}`} />}
            onClick={() => handleVehicleSelect(v)}
            style={{
              border: isVehicleSelected(v) ? "2px solid #1890ff" : "1px solid #d9d9d9",
              boxShadow: isVehicleSelected(v) ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none",
            }}
            className="vehicle-card"
          >
           <Card.Meta
          title={<div className="vehicle-card-title">{`${v.vehicleName} - ${v.numberOfPassengers} Passengers`}</div>}
          description={`Total Price: $${Math.round(calculatePriceForVehicle(v))}`}
        />
      </Card>
        </Col>
      ))}
    </Row>
  </div>

  )}

    {selectedVehicle && (
    <>
      <Form.Item label="Pickup Date" name="pickupDate" rules={[{ required: true }]}>
        <DatePicker
          disabledDate={(current) => current && current < moment().startOf("day")}
          onChange={handlePickupDateChange}
        />
      </Form.Item>


      <Form.Item label="Pickup Time" name="pickupTime" rules={[{ required: true }]}>
    <TimePicker
        format="h:mm A"
        onChange={handlePickupTimeChange}
        use12Hours
    />
</Form.Item>
    </>
  )}



{showGuestForm && (
      <Form layout="vertical" onFinish={handleGuestFormSubmit}>
        <Form.Item label="Full Name" name="fullName" rules={[{ required: true }]}>
          <Input onChange={(e) => setGuestInfo({ ...guestInfo, fullName: e.target.value })} autoComplete="new-password"/>
        </Form.Item>
        <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
          <Input onChange={(e) => setGuestInfo({ ...guestInfo, phone: e.target.value })} autoComplete="new-password"/>
        </Form.Item>
        <Form.Item label="Email" name="email" rules={emailValidation}>
          <Input onChange={(e) => setGuestInfo({ ...guestInfo, email: e.target.value })} autoComplete="new-password"/>
        </Form.Item>

      </Form>
    )}
{!isAuthenticated && (
<>
  {showGuestOptions ? (
    <>
      <div className="guest-options">
            <Button onClick={handleLoginOption}>Login</Button>
            <Button onClick={handleContinueAsGuest}>Continue as Guest</Button>
            </div>
    </>
  ) : null}
</>
)}





{showSummary && (
  <div>
    <h4>Summary</h4>
    <p>Trip Type: {tripType}</p>
    <p>Pickup Location: {pickupLocation.display_name}</p>
    <p>Dropoff Location: {dropoffLocation.display_name}</p>
    <p>Passenger Count: {passengerCount}</p>
    <p>Vehicle: {selectedVehicle.vehicleName}</p>
    <p>Total Distance: {totalDistance.toFixed(2)} miles</p> {/* Use toFixed for distance */}
    <p>Total Price: ${Math.round(totalPrice).toFixed(2)}</p> {/* Round price and use toFixed */}

    <p>
      <strong>{isAuthenticated ? "User Info" : "Guest Info"}:</strong>
      {isAuthenticated && userDetails
        ? `Full Name: ${userDetails.fullName}, Email: ${userDetails.email}, Phone: ${userDetails.phone}`
        : `${guestInfo.fullName}, ${guestInfo.phone}, ${guestInfo.email}`}
    </p>

    <Form.Item label="Payment Method">
      <Select value={paymentMethod} onChange={handlePaymentMethodChange} placeholder="Select Payment Method">
        <Select.Option value="paypal">PayPal</Select.Option>
        <Select.Option value="pay-after-service">Pay after service</Select.Option>
      </Select>
    </Form.Item>

    {paymentMethod === "paypal" && (
      <div>
        <PayPalButtons
          createOrder={(data, actions) => {
            const amount = totalPrice.toFixed(2); // Keep it as 2 decimals
            return actions.order.create({
              purchase_units: [{
                amount: { value: amount },
              }],
            });
          }}
          onApprove={handlePayPalSuccess}
        />
      </div>
    )}

    {paymentMethod === "pay-after-service" && (
      <Button type="primary" htmlType="submit" loading={loading}>
        Submit
      </Button>
    )}
  </div>
)}



    <Modal
      title="Login"
      open={loginVisible}
      onCancel={() => setLoginVisible(false)}
      footer={null}
    >
      <Form layout="vertical" onFinish={handleLogin}>
        <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password />
        </Form.Item>
        <Button type="primary" htmlType="submit">Login</Button>
      </Form>
    </Modal>
  </Form>
  );
}

function HourlyCharterForm() {
  const [form] = Form.useForm();
  const { isAuthenticated, login } = useAuth();
  const [tripType, setTripType] = useState("one-way");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [passengerCount, setPassengerCount] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [pricePerKm, setPricePerKm] = useState(null);
  const [vehicle, setVehicle] = useState("");
  const [isSelectionComplete, setIsSelectionComplete] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [guestInfo, setGuestInfo] = useState({
    fullName: '',
    email: '',
    phone: ''
  });

  const [showGuestOptions, setShowGuestOptions] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalDistance, setTotalDistance] = useState(0);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [tripDuration, setTripDuration] = useState();
  const [pickupDate, setPickupDate] = useState(null);
  const [pickupTime, setPickupTime] = useState(null);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showGuestForm, setShowGuestForm] = useState(false);
  const [pricePerHour, setPricePerHour] = useState(null);
  const [userDetails, setUserDetails] = useState({
    email: '',
    phone: '',
    fullName: '',
  });



  const [isGuestFormComplete, setIsGuestFormComplete] = useState(false);
  const [isServiceAvailable, setIsServiceAvailable] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [loading, setLoading] = useState(false);
 useEffect(() => {
  const fetchPricingData = async () => {
    try {
      const [vehicleRes, pricePerKmRes, pricePerHourRes] = await Promise.all([
        axios.get("https://johnwayneshuttle.com/api/vehicles"),
        axios.get("https://johnwayneshuttle.com/api/price-per-kilometer"),
        axios.get("https://johnwayneshuttle.com/api/price-per-hour")
      ]);


      setVehicles(vehicleRes.data);
      setPricePerKm(pricePerKmRes.data?.price || 0);
      setPricePerHour(pricePerHourRes.data?.price || 0);
    } catch (error) {
      console.error("Failed to fetch vehicles or pricing", error);
    }
  };

  fetchPricingData();
}, []);


useEffect(() => {
  if (isAuthenticated) {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token || isTokenExpired(token)) {
          console.error('Token is expired or not present. Please log in again.');

          return;
        }

        const response = await axios.get('https://johnwayneshuttle.com/api/users', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserDetails(response.data);
        console.log('User details fetcheds:', response.data);
      } catch (error) {
        console.error('Error fetching user details:', error.response?.data || error.message);
      }
    };

    const isTokenExpired = (token) => {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    };

    fetchUserDetails();
  }
}, [isAuthenticated]);

   useEffect(() => {
    const filterVehiclesByPassengers = () => {
      const filtered = vehicles.filter(v => v.numberOfPassengers >= passengerCount);
      setFilteredVehicles(filtered);
    };

    filterVehiclesByPassengers();
  }, [passengerCount, vehicles]);

  useEffect(() => {
    if (pickupLocation && dropoffLocation && passengerCount > 0 && tripDuration > 0) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [pickupLocation, dropoffLocation, passengerCount, tripDuration]);

  useEffect(() => {
    const filtered = vehicles.filter(v => v.numberOfPassengers >= passengerCount);
    setFilteredVehicles(filtered);
  }, [passengerCount, vehicles]);

  useEffect(() => {
    if (pickupLocation && dropoffLocation && selectedVehicle) {
      const distance = calculateDistance(
        pickupLocation.lat,
        pickupLocation.lon,
        dropoffLocation.lat,
        dropoffLocation.lon
      );
      setTotalDistance(distance);

      console.log("Distance:", distance);
      console.log("Selected Vehicle:", selectedVehicle);
      console.log("vehicleprice:", selectedVehicle.vehiclePrice);

      if (pricePerKm !== null && pricePerHour !== null && selectedVehicle) {
        const distancePrice = distance * pricePerKm;
        const hourlyPrice = tripDuration * pricePerHour;
        const vehiclePrice = selectedVehicle.vehiclePrice;

        console.log("Distance Price:", distancePrice);
        console.log("Hourly Price:", hourlyPrice);
        console.log("Vehicle Price:", vehiclePrice);

        if (!isNaN(distancePrice) && !isNaN(hourlyPrice) && !isNaN(vehiclePrice)) {
          const totalPrice = distancePrice + hourlyPrice + vehiclePrice;
          console.log("Total Price Calculated:", totalPrice);
          setTotalPrice(totalPrice);
        } else {
          console.error("Invalid price calculation inputs");
          setTotalPrice(0);
        }
      }
    }
  }, [pickupLocation, dropoffLocation, tripType, pricePerKm, pricePerHour, selectedVehicle, tripDuration]);


  const onFinish = async (values) => {
    setLoading(true);

    if (!pickupDate || !pickupTime) {
      message.error("Please select both a pickup date and time.");
      return;
    }

    // Round totalPrice and format totalDistance
    const roundedTotalPrice = Math.round(totalPrice).toFixed(2); // Round and format the total price
    const formattedTotalDistance = totalDistance.toFixed(2); // Format the total distance

    const bookingData = {
      ...values,
      serviceType: "Hourly Charter",
      vehicle: selectedVehicle.vehicleName,
      tripType,
      pickupDate: pickupDate.format("YYYY-MM-DD"),
      pickupTime: pickupTime.format("HH:mm"),
      userInfo: isAuthenticated
        ? {
            fullName: userDetails.fullName,
            email: userDetails.email,
            phone: userDetails.phone,
          }
        : {
            fullName: guestInfo.fullName,
            phone: guestInfo.phone,
            email: guestInfo.email,
          },
      totalPrice: roundedTotalPrice, // Use rounded totalPrice here
      pricePerKm,
      pricePerHour,
      totalDistance: formattedTotalDistance, // Use formatted totalDistance here
      paymentMethod,
      pickupLocation,
      dropoffLocation,
      passengerCount,
      vehicleName: selectedVehicle.vehicleName,
      vehiclePrice: selectedVehicle.vehiclePrice,
    };

    try {
      const response = await axios.post(
        "https://johnwayneshuttle.com/api/submit-booking",
        bookingData
      );
      console.log("Booking successful:", response.data);

      // Show success modal
      Modal.success({
        title: "Booking Successful!",
        content: (
          <div>
            <p><strong>Full Name: </strong> {isAuthenticated ? userDetails.fullName : guestInfo.fullName}</p>
            <p><strong>Email: </strong>{isAuthenticated ? userDetails.email : guestInfo.email}</p>
            <p><strong>Service Type: </strong>{bookingData.serviceType}</p>
            <p><strong>Pickup Location: </strong>{pickupLocation.display_name}</p>
            <p><strong>Dropoff Location: </strong>{dropoffLocation.display_name}</p>
            <p><strong>Pickup Date: </strong>{bookingData.pickupDate}</p>
            <p><strong>Pickup Time: </strong>{bookingData.pickupTime}</p>
            <p><strong>Amount: </strong> ${roundedTotalPrice}</p> {/* Display rounded totalPrice */}
            <p><strong>Payment Method: </strong>{paymentMethod}</p>
            <p>Vehicle: {bookingData.vehicleName}</p>
          </div>
        ),
        onOk: () => {
          // Reload the page when the modal is closed
          window.location.reload();
        },
      });

      // Reset form and clear state
      form.resetFields();
      resetForm();
    } catch (error) {
      console.error("Error booking hourly charter:", error);
      message.error("Failed to submit booking. Please try again.");
    } finally {
      setLoading(false);
    }

    console.log("Booking Data:", bookingData);
  };




const resetForm = () => {
  setPassengerCount(1);
  setPickupLocation("");
  setDropoffLocation("");
  setTotalDistance(0);
  setVehicle("");
  setShowGuestOptions(false);
  setShowSummary(false);
  setTripDuration(0);
  setTotalPrice(0);
  form.resetFields();

};


  const handleTripTypeChange = (value) => {
    setTripType(value);
    setPickupLocation("");
    setDropoffLocation("");
    setTotalDistance(0);
  };

  const handlePassengerCountChange = (value) => {
    setPassengerCount(value);

      const suitableVehicles = vehicles.filter(v => v.numberOfPassengers >= value);

      if (suitableVehicles.length === 0) {
        message.error(`No vehicles available for ${value} passengers. Please reduce the number of passengers.`);
      } else {
        message.success(`Vehicles available for ${value} passengers.`);
      }
  };



  const checkSelectionComplete = (pickup, dropoff, vehicle) => {
    setIsSelectionComplete(!!(pickup && dropoff && vehicle));
  };

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };

  const handlePayPalSuccess = async (data, actions, values) => {
    setLoading(true);
    try {
      const order = await actions.order.capture();
      console.log("PayPal payment successful:", order);
      setIsPaymentCompleted(true);

      const paypalTransactionId = order.id;
      const paypalPayerEmail = order.payer.email_address;

      // Round totalPrice and format totalDistance
      const roundedTotalPrice = Math.round(totalPrice).toFixed(2); // Round totalPrice and format it
      const formattedTotalDistance = totalDistance.toFixed(2); // Format totalDistance to 2 decimal places

      const bookingData = {
        ...values,
        serviceType: "Hourly Charter",
        vehicle: selectedVehicle.vehicleName,
        tripType,
        pickupDate: pickupDate.format("YYYY-MM-DD"),
        pickupTime: pickupTime.format("HH:mm"),
        userInfo: isAuthenticated
          ? {
              fullName: userDetails.fullName,
              email: userDetails.email,
              phone: userDetails.phone,
            }
          : {
              fullName: guestInfo.fullName,
              phone: guestInfo.phone,
              email: guestInfo.email,
            },
        totalPrice: roundedTotalPrice, // Use rounded totalPrice here
        pricePerKm,
        pricePerHour,
        totalDistance: formattedTotalDistance, // Use formatted totalDistance here
        paymentMethod,
        pickupLocation,
        dropoffLocation,
        passengerCount,
        vehicleName: selectedVehicle.vehicleName,
        vehiclePrice: selectedVehicle.vehiclePrice,
        paypalPayerEmail,
        paypalTransactionId,
      };

      try {
        const response = await axios.post(
          "https://johnwayneshuttle.com/api/submit-booking",
          bookingData
        );
        console.log("Booking successful:", response.data);

        // Show success modal
        Modal.success({
          title: "Booking Successful with PayPal!",
          content: (
            <div>
              <p><strong>Full Name: </strong> {isAuthenticated ? userDetails.fullName : guestInfo.fullName}</p>
              <p><strong>Email: </strong>{isAuthenticated ? userDetails.email : guestInfo.email}</p>
              <p><strong>Service Type: </strong> {bookingData.serviceType}</p>
              <p><strong>Pickup Location: </strong>{pickupLocation.display_name}</p>
              <p><strong>Dropoff Location: </strong>{dropoffLocation.display_name}</p>
              <p><strong>Pickup Date: </strong>{bookingData.pickupDate}</p>
              <p><strong>Pickup Time: </strong>{bookingData.pickupTime}</p>
              <p><strong>Amount Paid: </strong>${roundedTotalPrice}</p> {/* Display rounded totalPrice */}
              <p><strong>Payment Method: </strong>{paymentMethod}</p>
              <p><strong>Vehicle: </strong>{bookingData.vehicleName}</p>
              <p><strong>PayPal Transaction ID: </strong> {paypalTransactionId}</p>
              <p><strong>PayPal Payer Email: </strong>{paypalPayerEmail}</p>
            </div>
          ),
          onOk: () => {
            // Reload the page when the modal is closed
            window.location.reload();
          },
        });

        // Reset form and clear state
        form.resetFields();
        setPassengerCount(1);
        setPickupLocation("");
        setDropoffLocation("");
        setTotalDistance(0);
        setVehicle("");
        setShowGuestOptions(false);
        setShowSummary(false);
      } catch (error) {
        console.error("Error booking hourly charter:", error);
        message.error("Failed to submit booking. Please try again.");
      }
    } catch (error) {
      console.error("PayPal payment failed:", error);
      message.error("PayPal payment failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };



  const fetchLocationSuggestions = async (query) => {
    try {
      const response = await axios.get(`https://johnwayneshuttle.com/api/locations?query=${query}`);
      setLocationSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  };

   useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedQuery) {
        fetchLocationSuggestions(debouncedQuery);
      } else {
        setLocationSuggestions([]);
      }
    }, 300);

    return () => clearTimeout(handler);
  }, [debouncedQuery]);
   useEffect(() => {
    setDebouncedQuery(searchQuery);
  }, [searchQuery]);

  const handlePickupSearch = (value) => {
    setSearchQuery(value);
    if (value) {
      fetchLocationSuggestions(value);
    } else {
      setLocationSuggestions([]);
    }
  };

  const handlePickupSelect = (locationName) => {
    const location = locationSuggestions.find((loc) => loc.display_name === locationName);
    if (location) {
      setPickupLocation(location);
    }
    checkAvailability(location, dropoffLocation);
  };

 const handleDropoffSelect = (locationName) => {
  const location = locationSuggestions.find((loc) => loc.display_name === locationName);
  if (location) {
    setDropoffLocation(location);
  }
  checkAvailability(pickupLocation, location);
};
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3959;
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};


  const handleLogin = async (values) => {
    try {
      await login(values.username, values.password);
      setLoginVisible(false);
      setShowSummary(true);
      setShowGuestOptions(false);
    } catch (error) {
      console.error("Login failed:", error);
      message.error("Login failed. Please try again.");
    }
  };

  useEffect(() => {
    if (pickupDate && pickupTime) {
      if (!isAuthenticated) {
        setShowGuestOptions(true);
      } else {
        setShowSummary(true);
      }
    }
  }, [pickupDate, pickupTime, isAuthenticated]);

  useEffect(() => {
    if (guestInfo.fullName && guestInfo.phone && guestInfo.email) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [guestInfo]);

  useEffect(() => {
    if (isGuestFormComplete) {
      setShowSummary(true);
    }
  }, [isGuestFormComplete]);

   const handlePickupDateChange = (date) => {
    setPickupDate(date);
    checkSelectionComplete();
  };

  const handlePickupTimeChange = (time) => {
    setPickupTime(time);
    checkSelectionComplete();
  };

  const disabledDateTime = (pickupDate) => {
    const now = moment();

    if (pickupDate && pickupDate.isSame(now, 'day')) {
        return {
            disabledHours: () => {
                const hours = [];
                for (let i = 0; i < now.hour(); i++) {
                    hours.push(i);
                }
                return hours;
            },
            disabledMinutes: (selectedHour) => {
                if (selectedHour === now.hour()) {
                    const minutes = [];
                    for (let i = 0; i <= now.minute(); i++) {
                        minutes.push(i);
                    }
                    return minutes;
                }
                return [];
            },
        };
    }

    return {};
};
const handleVehicleSelect = (vehicle) => {
  setSelectedVehicle(vehicle);
  console.log("Selected Vehicle:", vehicle);
};


const isVehicleSelected = (vehicle) => {
  return selectedVehicle && selectedVehicle._id === vehicle._id;
};
useEffect(() => {
  if (pickupDate && pickupTime) {
    if (!isAuthenticated) {
      setShowGuestOptions(true);
    } else {
      setShowSummary(true);
    }
  }
}, [pickupDate, pickupTime, isAuthenticated]);
const handleContinueAsGuest = () => {
  setShowGuestForm(true);
  setShowGuestOptions(false);
  setLoginVisible(false);
};



const handleLoginOption = () => {
  setLoginVisible(true);
  setShowGuestForm(false);
};
const handleGuestFormSubmit = (values) => {
  setGuestInfo(values);
  setIsGuestFormComplete(true);
  setShowSummary(true);
};
useEffect(() => {
  if (isGuestFormComplete || isAuthenticated) {
    if (pickupDate && pickupTime && pickupLocation && dropoffLocation) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }
}, [isGuestFormComplete, isAuthenticated, pickupDate, pickupTime, pickupLocation, dropoffLocation]);
const calculatePriceForVehicle = (vehicle) => {
  if (pickupLocation && dropoffLocation && tripDuration) {
    const vehiclePrice = vehicle.vehiclePrice;
    const totalPrice = vehiclePrice * tripDuration; // Calculate based on vehiclePrice and tripDuration
    return totalPrice;
  }
  return 0;
};


const checkAvailability = (pickup, dropoff) => {
  if (pickup && dropoff) {
    // Assuming pickup and dropoff locations have lat and lon properties
    const distance = calculateDistance(pickup.lat, pickup.lon, dropoff.lat, dropoff.lon);

    if (distance > 500) {
      setIsServiceAvailable(false);
      message.error("Service is not available for distances greater than 500 miles. Please select a different location.");
    } else {
      setIsServiceAvailable(true);
      message.success("Service is available for your selected locations.");
    }
  }
};

const emailValidation = [
  {
    required: true,
    message: 'Please enter your email',
  },
  {
    type: 'email',
    message: 'The input is not a valid email!',
  },
];

  return (

    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Trip Type">
        <Select value={tripType} onChange={handleTripTypeChange} placeholder="Select Trip Type">
          <Select.Option value="one-way">One-Way</Select.Option>
          <Select.Option value="round-trip">Round Trip</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Pickup Location" name="pickupLocation" rules={[{ required: true }]}>
        <Select
          showSearch
          value={searchQuery}
          onSearch={handlePickupSearch}
          onChange={(locationName) => {
            setSearchQuery(locationName);
            handlePickupSelect(locationName);
          }}
          placeholder="Enter Pickup Location"
          filterOption={false}
          notFoundContent={searchQuery ? "No matches found" : null}
        >
          {locationSuggestions.map((location) => (
            <Select.Option key={location.display_name} value={location.display_name}>
              {location.display_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Dropoff Location" name="dropoffLocation" rules={[{ required: true }]}>
        <Select
          showSearch
          value={searchQuery}
          onSearch={handlePickupSearch}
          onChange={(locationName) => {
            setSearchQuery(locationName);
            handleDropoffSelect(locationName);
          }}
          placeholder="Enter Dropoff Location"
          filterOption={false}
          notFoundContent={searchQuery ? "No matches found" : null}
        >
          {locationSuggestions.map((location) => (
            <Select.Option key={location.display_name} value={location.display_name}>
              {location.display_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Trip Duration (hours)" name="tripDuration" rules={[{ required: true }]}>
        <InputNumber
          min={1}
          placeholder="Enter trip duration"
          onChange={(value) => setTripDuration(value)}
          disabled={!isServiceAvailable}
        />
      </Form.Item>

      <Form.Item label="Number of Passengers" name="passengerCount" rules={[{ required: true }]}>
        <InputNumber
          min={1}
          max={100}
          placeholder="Number of Passengers"
          onChange={(value) => handlePassengerCountChange(value)}
          disabled={!isServiceAvailable}
        />
      </Form.Item>

     {/* Vehicle Selection */}
    {isFormComplete && (
      <div>
      <h3>Select a Vehicle</h3>
      <Row gutter={16}>
        {filteredVehicles.map((v) => (
          <Col  key={v._id}
          xs={24}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          className="vehicle-list">
            <Card
              hoverable
              cover={<img alt={v.vehicleName} src={`https://johnwayneshuttle.com/uploads/${v.image}`} />}
              onClick={() => handleVehicleSelect(v)}
              style={{
                border: isVehicleSelected(v) ? "2px solid #1890ff" : "1px solid #d9d9d9",
                boxShadow: isVehicleSelected(v) ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none",
              }}
              className="vehicle-card"
            >
              <Card.Meta
  title={<div className="vehicle-card-title">{`${v.vehicleName} - ${v.numberOfPassengers} Passengers`}</div>}
  // Round the price and display it as a currency
  description={`Total Price: $${Math.round(calculatePriceForVehicle(v)).toFixed(2)}`}
/>

            </Card>
          </Col>
        ))}
      </Row>
    </div>

    )}
      {/* Show Pickup Date and Time after vehicle is selected */}
      {selectedVehicle && (
      <>
        <Form.Item label="Pickup Date" name="pickupDate" rules={[{ required: true }]}>
          <DatePicker
            disabledDate={(current) => current && current < moment().startOf("day")}
            onChange={handlePickupDateChange}
          />
        </Form.Item>


        <Form.Item label="Pickup Time" name="pickupTime" rules={[{ required: true }]}>
    <TimePicker
        format="h:mm A" // Updated format to 12-hour format
        onChange={handlePickupTimeChange}
        // disabledTime={disabledDateTime}
        use12Hours // Enable 12-hour clock
    />
</Form.Item>
      </>
    )}



{showGuestForm && (
        <Form layout="vertical" onFinish={handleGuestFormSubmit}>
          <Form.Item label="Full Name" name="fullName" rules={[{ required: true }]}>
            <Input onChange={(e) => setGuestInfo({ ...guestInfo, fullName: e.target.value })} autoComplete="new-password"/>
          </Form.Item>
          <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
            <Input onChange={(e) => setGuestInfo({ ...guestInfo, phone: e.target.value })} autoComplete="new-password"/>
          </Form.Item>
          <Form.Item label="Email" name="email" rules={emailValidation} types="email">
            <Input onChange={(e) => setGuestInfo({ ...guestInfo, email: e.target.value })} autoComplete="new-password"/>
          </Form.Item>

        </Form>
      )}
{!isAuthenticated && (
  <>
    {showGuestOptions ? (
      <>
        <div className="guest-options">
            <Button onClick={handleLoginOption}>Login</Button>
            <Button onClick={handleContinueAsGuest}>Continue as Guest</Button>
            </div>
      </>
    ) : null}
  </>
)}





{showSummary && (
  <div>
    <h4>Summary</h4>
    <p>Trip Type: {tripType}</p>
    <p>Pickup Location: {pickupLocation.display_name}</p>
    <p>Dropoff Location: {dropoffLocation.display_name}</p>
    <p>Passenger Count: {passengerCount}</p>
    <p>Vehicle: {selectedVehicle.vehicleName}</p>
    <p>Total Distance: {totalDistance} miles</p>
    <p>Total Price: ${Math.round(totalPrice).toFixed(2)}</p>

    <p>
  <strong>{isAuthenticated ? "User Info" : "Guest Info"}:</strong>
  {isAuthenticated && userDetails
    ? `Full Name:${userDetails.fullName}, Email: ${userDetails.email}, Phone: ${userDetails.phone}`
    : `${guestInfo.fullName}, ${guestInfo.phone}, ${guestInfo.email}`}
</p>


    <Form.Item label="Payment Method">
      <Select value={paymentMethod} onChange={handlePaymentMethodChange} placeholder="Select Payment Method">
        <Select.Option value="paypal">PayPal</Select.Option>
        <Select.Option value="pay-after-service">Pay after service</Select.Option>
      </Select>
    </Form.Item>

    {paymentMethod === "paypal" && (
      <div>
        <PayPalButtons
          createOrder={(data, actions) => {
            const amount = totalPrice.toFixed(2);
            return actions.order.create({
              purchase_units: [{
                amount: { value: amount },
              }],
            });
          }}
          onApprove={handlePayPalSuccess}
        />
      </div>
    )}

{/* Conditionally render the Confirm Booking button */}
{paymentMethod === "pay-after-service" && (
           <Button type="primary" htmlType="submit" loading={loading}>
           Submit
         </Button>
        )}
    <div>

  </div>
  </div>
)}

      <Modal
        title="Login"
        open={loginVisible}
        onCancel={() => setLoginVisible(false)}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleLogin}>
          <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password />
          </Form.Item>
          <Button type="primary" htmlType="submit">Login</Button>
        </Form>
      </Modal>
    </Form>

  );
}




// Styled Components for cards and animation
const Wrapper = styled.section`
  width: 100%;
`;

const ServiceBoxRow = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 30px;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 860px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 15px;
  color: #ff6b6b;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

// Styled button with animation
const StyledButton = styled(Button)`
  background: linear-gradient(180deg, #074173, #025aa8);
  border: none;
  color: white;
  padding: 24px 30px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  align-self: center; // Aligns the button to the bottom
  margin-top: 20px; // Pushes the button to the bottom
  &:hover {
    background-color: #fff;
    transform: scale(1.05);
    color: black;
  }
`;

