import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { keyframes } from "styled-components";
import "./home.css"
// Components
import FullButton from "../Buttons/FullButton";

// Assets

import HeaderImage from "../../assets/images/gmc1.png"
import Dots from "../../assets/svg/Dots";

export default function Header() {

  const contactDetails = {
    cell: "714-757-3249"
  };
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">FIND AND BOOK THE BEST CARS EASILY</h1>
          <HeaderP className="font13 semiBold">
            Want to book a car for camping, city riding, or airport? Book the best cars from us to get the best experience. <br />
            <ContactWrapper>
              <ContactItem>
                <Label>Cell:</Label>
                <AnimatedSpan>{contactDetails.cell}</AnimatedSpan>
              </ContactItem>
              <ContactItem>
                <Label>Office:</Label>
                <AnimatedSpan>{contactDetails.Office}</AnimatedSpan>
              </ContactItem>
            </ContactWrapper>
          </HeaderP>


          <Link
              activeClass="active"
              className="Home-btn"
              style={{ padding: "10px 15px" }}
              to="services"
              spy={true}
              smooth={true}
              offset={250}
            >
              Get Started
            </Link>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />

          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: -70px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
  padding-top: 80px;
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
// Animation Keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
// Typing Animation Keyframes
const typing = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

// Styled Components for Contact
const ContactWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContactItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Label = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
  color: #30479c;
`;

const AnimatedSpan = styled.span`
  font-size: 1rem;
  color: #333;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
  animation: ${typing} 5s steps(40) infinite, ${fadeIn} 0.8s ease-in-out;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
    text-decoration: underline;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }

`;
const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;

  /* Continuous animation */
  animation: zoomInOut 8s ease-in-out infinite;

  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05); /* Zoom in */
    }
    100% {
      transform: scale(1); /* Zoom out */
    }
  }
`;




const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


