import React, { useState, useEffect } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  BellOutlined,
  DashboardFilled,
  MessageFilled,
  BookOutlined,
  CarOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, Dropdown, Avatar, Badge, notification, theme } from 'antd';
import './Layout.css';
import { useAuth } from "../../../authcontext/AuthContext";
import AdminDashboard from '../adminDashboard/AdminDashboard';
import AdminBooking from '../adminDashboard/ViewBooking';
import AdminMessages from '../adminDashboard/AdminMessages';
import VehicleAndRoute from '../adminDashboard/VehicleAndRoute';
import { useNavigate, useLocation } from 'react-router-dom';

const { Header, Sider, Content } = Layout;

function Layouts({ children }) {
  const { logout, isAuthenticated, userRole } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('1');
  const [notifications, setNotifications] = useState([]);
  const [viewedBookingIds, setViewedBookingIds] = useState(new Set());
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // Load viewed booking IDs from the backend
  useEffect(() => {
    const fetchViewedBookingIds = async () => {
      try {
        const response = await fetch('https://johnwayneshuttle.com/api/viewedBookingIds');
        if (response.ok) {
          const data = await response.json();
          setViewedBookingIds(new Set(data.viewedBookingIds));
        } else {
          console.error('Error fetching viewed booking IDs:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching viewed booking IDs:', error);
      }
    };

    fetchViewedBookingIds();
  }, []);

  useEffect(() => {
    if (isAuthenticated && userRole === 'admin' && location.pathname !== '/admin') {
      navigate('/admin', { replace: true });
    }
  }, [isAuthenticated, userRole, location.pathname, navigate]);

  useEffect(() => {
    const POLL_INTERVAL = 5000;

    const fetchNewBookings = async () => {
      try {
        const response = await fetch('https://johnwayneshuttle.com/api/bookings');
        if (response.ok) {
          const allBookings = await response.json();

          // Create a set of existing booking IDs for quick lookup
          const existingBookingIds = new Set(notifications.map(notification => notification.booking._id));

          // Filter for new bookings that are not viewed or already notified
          const newBookings = allBookings.filter(booking =>
            !existingBookingIds.has(booking._id) &&
            !viewedBookingIds.has(booking._id)
          );

          if (newBookings.length > 0) {
            newBookings.forEach((booking) => {
              notification.info({
                message: 'New Booking',
                description: `New booking from ${booking.guestInfo.fullName}`,
                onClick: () => handleNotificationClick(booking._id),
              });
            });

            setNotifications(prevNotifications => [
              ...prevNotifications,
              ...newBookings.map(booking => ({
                key: booking._id,
                booking,
              })),
            ]);
          }
        } else {
          console.error('Error fetching new bookings:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching new bookings:', error);
      }
    };

    const intervalId = setInterval(fetchNewBookings, POLL_INTERVAL);

    return () => clearInterval(intervalId);
  }, [notifications, viewedBookingIds]);

  const handleMenuClick = (e) => {
    setSelectedMenuItem(e.key);
    setSelectedBookingId(null);
  };

  const handleNotificationClick = async (bookingId) => {
    setSelectedMenuItem('4'); // Set to View Bookings
    setSelectedBookingId(bookingId);

    try {
      // Mark the booking as viewed in the database
      await fetch(`https://johnwayneshuttle.com/api/bookings/${bookingId}/viewed`, {
        method: 'PATCH',
      });

      setViewedBookingIds(prevViewedIds => new Set(prevViewedIds).add(bookingId));
      setNotifications(prevNotifications =>
        prevNotifications.filter(notification => notification.booking._id !== bookingId)
      );
    } catch (error) {
      console.error('Error marking booking as viewed:', error);
    }
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case '1':
        return <AdminDashboard />;
      case '3':
        return <AdminMessages />;
      case '4':
        return <AdminBooking bookingId={selectedBookingId} onResetNotification={handleNotificationClick} />;
      case '5':
        return <VehicleAndRoute />;
      default:
        return <div>Select a menu item</div>;
    }
  };

  const notificationMenu = (
    <Menu>
      {notifications.length > 0 ? (
        notifications.map(notification => (
          <Menu.Item key={notification.key} onClick={() => handleNotificationClick(notification.booking._id)}>
            {notification.booking.guestInfo.name} - {notification.booking.serviceType}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item>No new notifications</Menu.Item>
      )}
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item key="3" onClick={logout}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed} className='sidebar'>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedMenuItem]}
          onClick={handleMenuClick}
          className='menu-item'
        >
          <Menu.Item key="1" icon={<DashboardFilled />}>Dashboard</Menu.Item>
          <Menu.Item key="3" icon={<MessageFilled />}>Messages</Menu.Item>
          <Menu.Item key="4" icon={<BookOutlined />}>View Bookings</Menu.Item>
          <Menu.Item key="5" icon={<CarOutlined />}>Add Vehicle</Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className='header-layout' style={{ padding: 0, background: colorBgContainer }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined className='menu-btn' /> : <MenuFoldOutlined className='menu-btn' />}
              onClick={() => setCollapsed(!collapsed)}
              style={{ fontSize: '16px', width: 64, height: 64 }}
              className='menu-button'
            />
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginRight: '16px' }} className='header-menus'>
              <Dropdown overlay={notificationMenu} trigger={['click']}>
                <Badge count={notifications.length}>
                  <BellOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
                </Badge>
              </Dropdown>
              <Dropdown overlay={userMenu} trigger={['click']}>
                <Avatar size="large" icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content
          className='content-layout'
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
}

export default Layouts;

